@import "font-awesome.css";

@font-face {
	font-family: 'Bree Serif';
	src: url('RESOURCE/fonts/BreeSerif-Regular.eot');
	src: url('RESOURCE/fonts/BreeSerif-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/BreeSerif-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/BreeSerif-Regular.woff') format('woff'),
		url('RESOURCE/fonts/BreeSerif-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/BreeSerif-Regular.svg#BreeSerif-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

body {
	font-family: 'Source Sans Pro', sans-serif;
}

h1,
h2,
h3,
h4 {
	font-family: 'Bree Serif', serif;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--PrimaryColor);
	border-color: var(--PrimaryColor);
}

.doc-box {
	text-align: left;
}

.checkbox label:after,
.checkbox label:before {
	outline: none !important;
}

.doc-box {
	.box {
		.body {
			>div {
				overflow-y: scroll;
				height: calc(100vh - 200px);
				max-height: 640px;
			}
		}
	}
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	/* @mixin flexlayout column;*/
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
		text-align: left !important;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #2e76bc;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		color: var(--greycolor) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
		color: rgb(86, 90, 92) !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		/*color: rgba(255, 255, 255) !important;*/

	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

#back_to_top {
	position: fixed;
	bottom: 50px;
	right: 20px;
	border-radius: 4px;
	border: 1px solid var(--PrimaryColor);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	padding: 0;
	font-size: 15px;
	background: var(--PrimaryColor);
	z-index: 1000;

	i {
		margin-right: 0;
		font-size: 16px;
		color: var(--whitecolor);
	}

	&:hover {
		background-color: var(--blackcolor);
		border-color: var(--blackcolor);
	}

	@media (max-width:992px) {
		right: -54px;

		i {
			margin-right: 20px;
		}
	}
}

.pad60 {
	padding: 60px 0;

	@media (max-width:450px) {
		padding: 30px 0;
	}
}

.mt-30 {
	margin-top: 30px;
}

.mt-45 {
	margin-top: 45px !important;
}

.primarycolortext {
	color: var(--PrimaryColor);
}

.bold-text {
	font-weight: bold;
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 600 !important;
}

.fa.fa-heart-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.fa.fa-heart-o:before {
	content: "\f004";
}

.fa.fa-envelope-o {
	font-weight: 400;
}