@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.map-pane {

	&.layout-column {
		@media (min-width:993px) {
			height: 580px;
		}

		@media (max-width:992px) {
			height: 520px;

		}

		@media (max-width:420px) {
			height: 560px;
		}


		.no-map {
			.mapconsent {
				height: 100%;
			}


		}
	}



	.mapnote {
		padding: 0 0 20px;

		@media (max-width:992px) {
			padding: 15px 0;
		}

	}

	.map-main {
		height: 100%;

		>.search-map-area {
			width: 100%;

			height: calc(100% - 55px);

			@media (max-width:440px) {
				height: calc(100% - 155px);
			}



			>div {
				height: 100%;
			}
		}
	}


	.no-map {


		>.search-map-area {
			width: 100%;
			height: 100%;

			>div {
				height: 100%;

				.mapconsent {

					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 0 15px;


					.mapconsent-button {
						background: var(--PrimaryColor);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondarycolor);
						}
					}
				}
			}
		}

	}

}

/*.list-view {
	.map-col {
		.affix {
			@media (min-width:993px) {
				position: fixed !important;
				top: 0;
				width: 100%;
				max-width: 480px;
			}
		}
	}
}
.map-view {
	.map-col {

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
			}
		}
	}
}


.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}*/

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 0 15px;

		color: #565a5c;


		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0px 0 0;
			border-bottom: 1px solid rgb(235 235 235 / 0.3);
			margin-bottom: 15px;

			h3 {
				color: var(--whitecolor);
				font-weight: 500;
				letter-spacing: .5px;
				font-size: 24px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 25px;
				display: -webkit-box;
				display: flex;
				width: 100%;

			}

			.sorting-trigger {
				margin-bottom: 30px;

				span {
					color: var(--whitecolor);
					font-size: 16px;
					margin: 0 5px 0 0;

					@media (max-width:767px) {
						display: block;
						margin-bottom: 5px;
					}
				}

				.sorting-item {
					border: 1px solid;
					border-radius: 1px;
					padding: 8px 20px 8px 15px;
					background: #fff;
					border-color: #e6eff2;
					color: #a9a9a9;
					font-weight: 400;
					font-size: 14pt;
					letter-spacing: .04em;
					-webkit-transition: all ease 0.3s;
					transition: all ease 0.3s;
					display: inline-block;
					line-height: 1.82857143;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					-ms-touch-action: manipulation;
					touch-action: manipulation;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					text-decoration: none;

					&:hover {
						color: var(--PrimaryColor);
					}


					&.icon-grid {
						&:before {
							content: "\f00a";
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							margin-right: 15px;
						}
					}

					&.icon-ascend {
						&:before {
							content: "\f15d";
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							margin-right: 15px;
						}
					}

					&.icon-tag {
						&:before {
							content: "\f02b";
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							margin-right: 15px;
						}
					}
				}

			}

			.sorting-item.activesorting {
				color: var(--Primarycolor);
			}

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 0 0 25px;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
			text-decoration: none;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.unit-cont-bl {
			margin: 0 !important;
			padding: 0 !important;
		}

		.unit-box {
			margin-bottom: 0 !important;
		}

		p {
			height: 100px;
			overflow: auto !important;
			padding: 00px !important;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.unit-cont-bl {
			height: auto !important;

			p {
				margin: 10px 0;
				font-size: 13px;
				line-height: 17px;
				text-decoration: none !important;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}


	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;
		background-color: rgb(10 70 128 / 0.8);
		flex-wrap: wrap;
		padding: 5px;
		height: 50px;
		overflow: hidden;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #fff;
			border: 1px solid #fff;
			padding: 5px 10px;
			margin: 5px;
			font-weight: 700;
			height: 30px;

			.ft-title {
				margin-left: 5px;
			}
		}

		@media (max-width: 767px) {
			height: auto;
		}
	}

	.unit-btn {
		a.btn {
			background: var(--blackcolor);
			color: var(--whitecolor);
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0.5px;
			padding: 10px 10px;
			border-radius: 0;
			/*text-transform: uppercase;*/
			-webkit-transition: all ease 0.3s;
			transition: all ease 0.3s;

			&:hover {
				background: var(--secondarycolor);
				color: var(--whitecolor);
			}
		}
	}

	.search-bar {
		padding: 150px 0 50px 0;
		background: url(RESOURCE/img/search-banner1.jpg);
		background-position: 50% 50%;
		background-size: cover;
		position: relative;

		.container {
			background: hsla(0, 0%, 100%, 0.8);
			padding: 50px 50px 30px 50px;

			h4 {
				color: var(--PrimaryColor);
				font-weight: 400;
				letter-spacing: .5px;
				font-size: 24px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 25px 0;
				display: flex;
				width: 100%;
			}

			.top {
				display: flex;
				width: 100%;
				align-items: flex-start;

				.map-btn {
					a {
						background: var(--PrimaryColor);
						color: #fff;
						height: 35px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 15px;
						font-size: 15px;
						text-decoration: none;
						border: 1px solid var(--PrimaryColor);
						margin: 0 0 25px 0;

						&:hover {
							background: var(--secondarycolor);
							border: 1px solid var(--secondarycolor);
						}
					}
				}

				@media (max-width: 480px) {
					flex-wrap: wrap;
				}
			}



			.input-lg,
			select,
			input {
				padding: 0 15px;
				font-size: 18px;
				border-radius: 0;
				border: none;
				height: 50px;
				font-size: 18px;
				margin: 0 0 20px 0;
				background: #fff;
			}

			.checkbox {
				margin: 0;
				display: flex;
				align-items: center;
				padding: 0;

				label {
					font-size: 17px !important;
					font-weight: 500;
					padding: 0 0 0 20px;
					color: #000;

					&:before {
						margin: 4px 0 0 0;
					}

					&:after {
						left: 20px;
						top: 4px;
					}
				}

				input {
					height: auto;
					margin: 0;
				}
			}

			.bottom {
				padding: 0 0 20px 0;
				display: flex;

				.checkbox-success input[type=checkbox]:checked+label:before {
					background-color: var(--PrimaryColor);
					border-color: var(--PrimaryColor);
					outline: none !important;
				}

				@media (max-width: 768px) {
					flex-wrap: wrap;
					padding: 0;
				}
			}

		}

		&.search-banner {
			background-image: url(RESOURCE/img/search-banner1.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.form-control {
				margin-bottom: 5px;
			}
		}

		label[for="cb_pets"] {
			color: var(--whitecolor);
			font-size: 15px;
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			border: 0px solid var(--whitecolor);
			position: relative;
			margin-bottom: 30px;
			background-color: var(--whitecolor);

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;

				figure {
					background-color: var(--primarycolor);
					padding: 5px 10px;
					color: var(--whitecolor);
					font-weight: bold;
				}
			}

			.unit-title {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgb(10 70 128 / 0.75);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				/*text-transform: uppercase;*/
				font-size: 16pt;
				font-weight: 400;
				/*font-family: Source Sans Pro, sans-serif;*/


				@media (max-width:767px) {
					font-size: 16px;
				}
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 5px;
					bottom: 5px;
					color: var(--primarycolor);
					font-size: 24px;
					cursor: pointer;

					.fa {
						color: var(--whitecolor);
					}
				}

				a {
					display: block;
					position: relative;
					background: none;
					overflow: hidden;
					-webkit-transition: all 0.3s ease-out;
					transition: all 0.3s ease-out;

					img {
						display: block;
						position: relative;
						-webkit-transition: all 0.3s ease-out;
						transition: all 0.3s ease-out;
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
					}

					&:hover img {
						-webkit-transform: scale(1.1);
						transform: scale(1.1);
						opacity: 1;
					}

				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				padding: 0 20px;
				height: 260px;
				margin-bottom: 18px;
				overflow: hidden;

				p {
					font-size: 15px;
					color: var(--defaultcolor);
					line-height: 25px;
					vertical-align: middle;
					overflow: hidden;
					margin: 10px 0;

				}
			}

			.unit-feat {
				background-color: var(--primarycolor);


			}
		}
	}


	.map-pane {
		/*@mixin flex;
		height: 100vh;*/
		height: 700px;


		@media (max-width:767px) {
			padding: 0 15px !important;
		}

		.leaflet-popup-content {
			padding: 13px 19px;
			line-height: 1.4;
			width: 300px !important;
			margin: 0;

			@media (max-width: 767px) {
				width: 290px !important;
			}
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}


		}






	}

	.search-list-content {
		background: var(--greycolor);

		.list-view {
			.map-col {
				display: none;
			}
		}
	}
}

.my-search-token {
	background-color: var(--whitecolor) !important;
	border-radius: 0 !important;
	padding: 5px 10px !important;
	border: 1px solid var(--PrimaryColor) !important;

	.close {
		margin: 0 0 0 5px;
		color: var(--PrimaryColor) !important;
		opacity: 1 !important;

	}
}

@media (max-width: 767px) {
	.results {
		margin: 0;

		.srow {
			padding: 0;
		}
	}

	.map-pane {

		padding: 0 30px !important;
	}
}