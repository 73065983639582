@import "setup.css";

.btn-primary {
	border-radius: 0;
	background: var(--PrimaryColor);
	border: 1px solid var(--PrimaryColor);

	&:hover {
		background: var(--secondarycolor);
		border: 1px solid var(--secondarycolor);
	}
}

.indexpage {
	font-family: 'Source Sans Pro', sans-serif;

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {

		height: 100vh;

		@media (min-width:1200px) {
			height: 100vh;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					/*font-family: 'Raleway', sans-serif;*/
					font-weight: 500;
					text-shadow: 0 0 10px #000;
					text-shadow: 0 0 10px #000;
					font-size: 30px;
					margin: 250px 0 0 0;

					@media (max-width: 480px) {
						font-size: 17px !important;
						margin: 75px 0 0 !important;
					}

					@media (max-width: 767px) {
						font-size: 28px;
						margin: 75px 0 0;
					}

				}

				h2 {
					font-size: 24px;
					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}



	.searchbar {

		position: absolute;
		background: rgba(255, 255, 255, 0.5);
		width: 100%;
		bottom: 40%;
		padding-top: 20px;
		padding-bottom: 20px;

		.searchbox {
			padding: 5px;
			display: flex;
			height: 50px;
			align-items: center;


			input,
			.search-btn,
			select {
				border-radius: 0;
				margin: 0 5px 0 0;
			}

			input[type=checkbox],
			input[type=radio] {
				width: 17px;
				height: 17px;
				border: 1px solid #ccc;
				border-radius: 3px;
				background-color: #fff;
			}

			label {
				margin: 0;
				font-size: 17px !important;
				font-weight: 500;
				padding: 0;
			}

			.search-btn {
				width: 100%;
				background-color: var(--PrimaryColor);
				border: 1px solid;
				border-color: var(--PrimaryColor);
				box-shadow: none;
				transition: all ease 0.3s;

				&:hover {
					background-color: var(--secondarycolor);
					color: #fff;
					border-color: var(--secondarycolor);
				}

				@media (min-width: 992px) and (max-width: 1199px) {
					padding: 10px 0;
				}
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				width: 100%;
				margin: 10px 0 0 0;
			}

		}

		@media (max-width: 767px) {
			bottom: 0;
		}

		@media (min-width:768px) {
			max-width: 100%;
			bottom: 20%;
		}

		@media (min-width:993px) {
			max-width: 100%;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}


	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

	.bg-image-section {
		background-size: cover;
		background-attachment: fixed;
		background-position: top;
		position: relative;

		&.bg-image1 {
			background-image: url(RESOURCE/img/bg-image-section1.jpg);
		}

		&.bg-dark {
			&:after {
				background-color: rgba(46, 118, 188, .6);
				content: "";
				width: 100%;
				height: 100%;
				top: 0;
				bottom: 0;
				position: absolute;
				z-index: 1;
			}

			color: var(--whitecolor);
		}

		.heading1 {
			position: relative;
			z-index: 2;
			color: #fff;
		}

		.content {
			position: relative;
			z-index: 2;
			font-size: 16px;

			p {
				color: #fff;
			}
		}
	}

	.heading1 {
		font-weight: 500;
		letter-spacing: .5px;
		font-size: 30px;
		line-height: 1.1em;
		margin: 0;
		padding: 0 0 25px;
		display: flex;
		width: 100%;
		color: var(--PrimaryColor);
		text-align: center;
		align-items: center;
		justify-content: center;
		font-family: 'Bree Serif', serif;
		letter-spacing: 1px;
	}

	.heading2 {
		color: #fff;
		text-align: center;
		align-items: center;
		justify-content: center;
		letter-spacing: 1px;
	}

	.sec-footer {
		font-size: 24px;
		/*font-weight: 600;*/
		font-family: 'Bree Serif', serif;
	}

	.item {
		background: #f7f7f7;
		min-height: 610px;
		margin: 0 0 30px 0;
		border: 1px solid #dedede;

		img {
			width: 100%;
		}

		h3 {
			font-size: 18px;
			font-weight: 400;
			margin: 0 0 0 0;
			padding: 20px;
		}

		p {
			font-size: 15px;
			color: var(--defaultcolor);
			line-height: 22px;
			vertical-align: middle;
			overflow: hidden;
			margin: 0 0;
			padding: 0 20px 20px 20px;
			min-height: 220px;

			@media (max-width: 991px) {
				min-height: auto;
			}

			@media (min-width: 992px) and (max-width: 1199px) {
				min-height: 270px;
			}
		}

		.btn {
			margin: 0 0 20px 20px;
			border-radius: 0;
			height: 40px;
			padding: 0 20px;
			line-height: 40px;
			font-size: 15px;
			font-weight: 500;
			background: var(--PrimaryColor);
			border: 1px solid var(--PrimaryColor);
			-webkit-transition: all .3s ease;
			transition: all .3s ease;

			&:hover {
				background: var(--secondarycolor);
				border: 1px solid var(--secondarycolor);
			}
		}

		@media (max-width: 767px) {
			min-height: auto;
		}
	}

	.home-unit-view {
		padding: 0 0 30px 0;

		.unit-box {
			border: 0px solid var(--whitecolor);
			position: relative;
			margin-bottom: 30px;
			background-color: var(--whitecolor);

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;

				figure {
					background-color: var(--primarycolor);
					padding: 5px 10px;
					color: var(--whitecolor);
					font-weight: bold;
				}
			}

			.unit-title {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgb(10 70 128 / 0.75);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				/*text-transform: uppercase;*/
				font-size: 16pt;
				font-weight: 500;
				margin: 0;
				/*font-family: Source Sans Pro, sans-serif;*/

				@media (max-width:767px) {
					font-size: 16px;
				}
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 5px;
					bottom: 5px;
					color: var(--primarycolor);
					font-size: 24px;
					cursor: pointer;

					.fa {
						color: var(--whitecolor);
					}
				}

				a {
					display: block;
					position: relative;
					background: none;
					overflow: hidden;
					-webkit-transition: all 0.3s ease-out;
					transition: all 0.3s ease-out;

					img {
						display: block;
						position: relative;
						-webkit-transition: all 0.3s ease-out;
						transition: all 0.3s ease-out;
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						height: 300px;
						object-fit: cover;
					}

					&:hover img {
						-webkit-transform: scale(1.1);
						transform: scale(1.1);
						opacity: 1;
					}

				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				padding: 0 20px 0px !important;
				height: 220px;
				margin-bottom: 18px;
				overflow: hidden;
				background: #f7f7f7;
				margin: 0;
				display: flex;
				width: 100%;

				p {
					font-size: 15px;
					color: var(--defaultcolor);
					line-height: 25px;
					vertical-align: middle;
					overflow: hidden;
					margin: 10px 0;
					text-align: center;
				}
			}

			.unit-feat {
				background-color: var(--primarycolor);
			}

			.search-result-properties {
				display: flex;
				text-align: center;
				-webkit-box-pack: center;
				justify-content: center;
				background-color: rgb(10 70 128/.8);
				flex-wrap: wrap;
				padding: 5px;

				.ft-icon {
					text-align: center;
					display: flex;
					align-items: center;
					color: #fff;
					border: 1px solid #fff;
					padding: 5px 10px;
					margin: 5px;
					align-items: center;
					justify-content: center;
					padding: 5px 10px;
					font-weight: 600;

					.ft-title {
						margin-left: 5px;
					}
				}
			}

			.unit-btn {
				a.btn {
					background: var(--blackcolor);
					color: var(--whitecolor);
					font-weight: 400;
					font-size: 18px;
					letter-spacing: .5px;
					padding: 10px;
					border-radius: 0;
					/*text-transform: uppercase;*/
					-webkit-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						background: var(--secondarycolor);
						color: var(--whitecolor);
					}
				}
			}
		}
	}
}

@media screen and (min-width: 769px) and (max-width: 992px) {
	.vo-nav-bar {
		position: absolute;
		right: 0;
		width: 100%;

		a.icon {
			float: right;
			display: block;
			position: relative;
			color: #fff;
			color: var(--whitecolor);
			border-radius: 50%;
			width: 27px;
			height: 27px;
			text-align: center !important;
			margin-left: 15px;
			line-height: 27px;
			margin: 0px 0 0 0;
		}
	}

	.vo-nav-bar.opened .navbar-area {
		display: block;
		box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
		top: 75px;
		top: 100%;
		margin: 24px 0 0 0;
		padding: 15px 0;

		li {
			width: 100%;
			padding: 5px 0;
		}
	}

	.nav-contact {
		margin: 0 35px 0 0;
	}

	.navbarheader nav .icon-bar {
		margin: 5px 0 !important;
	}
}