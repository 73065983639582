@import "common.css";

.static-view {
	.inner-banner {
		padding: 170px 0 30px;
		position: relative;
		height: 300px;
		overflow: hidden;
		font-size: 16px;

		.page-header {
			position: relative;
			z-index: 1;
			color: var(--whitecolor);
			margin-top: 0;
			border-bottom: none;

			h1 {
				color: var(--whitecolor);
				font-weight: 500;
				letter-spacing: .5px;
				font-size: 28px;
				line-height: 1.1em;
				margin: 0;
				padding: 50px 0 0 0;
				display: -webkit-box;
				width: 100%;
			}
		}

		.cover-img {
			position: absolute;
			top: 0;
			z-index: 0;
			height: 500px;
			width: 100%;
			background-position: center;
			background-size: cover;
			background-image: url(RESOURCE/img/impressum-banner.jpg);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 51, 102, .5);
		}
	}

	h3 {
		color: var(--PrimaryColor);
	}

	.content {
		padding: 50px 0;
		font-size: 16px;

		p {
			font-size: 16px;
			line-height: 26px;
			margin: 0 0 20px 0;
		}

		.pad-left-20 {
			padding-left: 20px;
		}
	}

}

.impressum {
	.cover-img {
		background-image: url(RESOURCE/img/impressum-banner.jpg);
	}


}

.contact-page {
	.map {
		margin-top: 105px;
		height: 550px;

		@media (max-width: 767px) {
			margin-top: 95px;
		}

		>div {
			height: 100%;
		}

		.contact-map {
			height: 100%;
		}
	}


	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
		padding-left: 15px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;
		height: 550px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;

		a {
			background: var(--PrimaryColor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondarycolor);
			}
		}
	}

	.ct-form-section {
		padding-top: 50px;
		padding-bottom: 50px;

		.txt-prim {
			color: var(--PrimaryColor);
			font-weight: 500;
			letter-spacing: .5px;
			font-size: 24px;
			line-height: 1.1em;
			margin: 0;
			padding: 0 0 25px;
			display: flex;
			width: 100%;
		}

		.map {
			margin: 0 0 50px 0
		}

		label {
			text-align: left;
			margin: 0 0 5px 0;
			font-size: 15px;
		}

		input {
			padding: 0 15px;
			border-radius: 0;
			height: 50px;
			font-size: 18px;
			margin: 0 0 0;
			background: #fff;
			box-shadow: none;
			border: 1px solid #ccc;
			width: 100%;
		}

		textarea {
			padding: 15px;
			border-radius: 0;
			font-size: 18px;
			margin: 0 0 0;
			background: #fff;
			box-shadow: none;
			border: 1px solid #ccc;
			width: 100%;
		}

		.btn-go {
			height: 45px;
			font-size: 15px;
			text-transform: uppercase;
			border-radius: 0;
			padding: 0 20px;
			background-color: var(--PrimaryColor);
			border: 1px solid var(--PrimaryColor);
			outline: none;


			&:hover {
				background-color: var(--secondarycolor);
				border: 1px solid var(--secondarycolor);
			}
		}

		.checkbox {
			input {
				width: auto !important;
			}
		}

		.list-icon {
			list-style: none;
			margin: 10px 0 0 0;
			background: #f7f7f7;
			padding: 40px;

			li {
				display: flex;
				color: var(--defaultcolor);
				margin: 0 0 15px;
				border-bottom: 1px dotted hsla(0, 0%, 89.4%, .8);
				font-size: 16px;
				font-weight: 500;
				padding: 0 0 15px 0;

				i {
					margin: 5px 10px 0 0;
				}

				a {
					color: #acbac4;
					text-decoration: none;

					&:hover {
						color: var(--PrimaryColor);
					}
				}
			}
		}
	}

}