:root {
	--introImg: url(RESOURCE/img/banner1.jpg);
	--introMobileImg: url(RESOURCE/img/banner1.jpg);
	--impressumbanner: url(RESOURCE/img/impressum-banner.jpg);
	--navbarbreak: 768px;
	--whitecolor: #fff;
	--PrimaryColor: #2e76bc;
	--blackcolor: #000;
	--greycolor: #b5bec3;
	--defaultcolor: #003366;
	--secondarycolor: #0a4680;
	--lightprimary: #f6edd8;
	--cal_not_available: #2e76bc;
}