@import "common.css";

.unit-view {
	.rent-note {
		height: 300px;
		overflow: auto;

		@media (max-width: 991px) {
			height: auto;
			overflow: unset;
		}
	}

	button {
		overflow: hidden !important;
		outline: none !important;
		border-radius: 0;
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.inner-banner {
		padding: 170px 0 30px;
		position: relative;
		height: 300px;
		overflow: hidden;

		.page-header {
			position: relative;
			z-index: 1;
			color: var(--whitecolor);
			margin-top: 0;
			border-bottom: none;

			h1 {
				font-size: 28px;

			}
		}

		.cover-img {
			position: absolute;
			top: 0;
			z-index: 0;
			height: 500px;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 51, 102, .5);
		}
	}

	.unit-list-section {
		.item {
			height: 60vh;
			min-height: 370px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

		}
	}

	.owl-carousel {
		.owl-nav {
			position: absolute;
			top: 45%;
			width: 100%;

			button {
				color: #fff !important;
				text-shadow: 1px 1px 1px #000;
				position: absolute;
				z-index: 999;
				background: transparent;
				border: 0;
				font-size: 65px !important;
				padding: 0 10px 10px !important;
				background: rgba(0, 0, 0, .4) !important;
				line-height: 1 !important;
				overflow: hidden;
				outline: none !important;

				&.owl-next {
					right: 0;
				}
			}
		}
	}

	.contact-form {
		padding: 5px;
		border: 1px solid var(--greycolor);
		margin-top: 30px;

		h2 {
			margin-top: 10px;
		}

		.form-group {
			.checkbox {
				display: flex;
				align-items: center;

				.doc-box {
					margin: 0 0 0 5px;

					a {
						font-style: normal;
						color: var(--PrimaryColor);
						text-decoration: none;

						&:hover {
							color: var(--secondarycolor);
						}
					}
				}
			}
		}
	}



	.search-section {
		.searchbox {
			margin: 0 0 12px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		h2 {
			margin-top: 15px;
			font-size: 22px;
			color: var(--defaultcolor);

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding: 0;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 10px;
				margin: 5px 0;

				.day {
					width: 24px;
					height: 24px;
					display: inline-block;
					margin: 0 10px 0 0;
					border: 1px solid #e5e5e5;

					&.day-free {
						background: var(--white);
					}

					&.day-full {
						background: var(--cal_not_available);
					}

					&.day-end {
						background: linear-gradient(90deg, #fff 70%, var(--cal_not_available) 80%) !important;
					}

					&.day-start {
						background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 30%) !important;

					}
				}
			}
		}

		.main-col {

			position: static;

			.stars {
				margin: 15px 0;
				font-size: 18px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.fa {
					color: var(--PrimaryColor);
				}
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				@mixin columnlayout 2;
				margin-bottom: 30px;

				.prop.bool {
					font-weight: bold;
				}

				.prop {
					padding: 0 15px;
					padding-left: 30px;
					position: relative;
					margin-bottom: 12px;

					&:before {
						content: "\f00c";
						color: var(--secondarycolor);
						position: absolute;
						left: 0;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
					}
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.info-block-side {
			border: 1.5px solid var(--secondarycolor);

			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: var(--defaultcolor);

				text-align: center;

				h2 {
					margin-top: 10px;
					color: var(--whitecolor);
					font-size: 18px;
				}
			}

			.info-col.noaction {

				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */



				.info-col-main {
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}


		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 10px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.subrow {
					.btn-default {
						border: 1px solid var(--PrimaryColor) !important;
						background: transparent !important;
						color: var(--PrimaryColor) !important;

						&:hover {
							background: ar(--PrimaryColor) !important;
							color: var(--whitecolor) !important;
						}
					}

					.book-btn {
						background: var(--PrimaryColor);
						border-color: var(--PrimaryColor);

						&:hover {
							border: 1px solid var(--PrimaryColor);
							background: transparent;
							color: var(--PrimaryColor);
						}
					}
				}

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
					height: 40px;
					padding: 0 10px;
					font-size: 15px;
					border-radius: 0;
					background: var(--PrimaryColor) !important;
					border-color: var(--PrimaryColor) !important;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						background: var(--secondarycolor) !important;
						border-color: var(--secondarycolor) !important;

					}
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}


		}

		.aboutus {
			border: 1px solid #b5bec3;
			padding: 10px;

			i {
				margin-right: 5px;
				color: var(--PrimaryColor);
			}

			.mt-8 {
				margin-top: 8px;
			}
		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 470px;
			width: 100%;



			@media (max-width:992px) {
				height: 520px;
			}

			@media (max-width:420px) {
				height: 560px;
			}

			.mapnote {
				font-size: 14px;
				padding: 20px 0;
				background: #fff;

			}

			>div {
				height: 350px;

				@media (max-width:991px) {
					height: 400px;
				}
			}

			&.no-map {
				height: auto;

				.mapconsent {
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 15px;
					margin: 15px 0;

					.doc-box {}

					.mapconsent-button {
						background: var(--PrimaryColor);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondarycolor);
						}
					}
				}

				@media (max-width:420px) {
					height: auto;
				}

				>div {
					height: auto;

					@media (max-width:991px) {
						height: auto;
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}




		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

	.optionals {
		.btn-warning {
			border: 1px solid var(--PrimaryColor);
			background: var(--PrimaryColor);
			color: #fff;

			&:hover {
				border: 1px solid var(--secondarycolor);
				background: var(--secondarycolor);
			}
		}
	}

}


/*
.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

*/

.reduced-width-container {
	h2 {
		font-size: 22px;

		color: var(--defaultcolor);
	}
}

.unit-proposals {

	.single-unit {
		margin-bottom: 30px;

		>a {
			display: block;
			position: relative;

			.full-img {
				width: 100%;
			}

			h3 {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgba(0, 51, 102, .8);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				font-size: 18px;
				font-weight: 500;
				margin-top: 0;
			}

			.udetails {
				display: flex;
				text-align: center;
				-webkit-box-pack: center;
				justify-content: center;
				background-color: rgba(0, 51, 102, .8);
				flex-wrap: wrap;
				padding: 5px;

				.ft-icon {
					display: inline-flex;
					-webkit-box-align: center;
					align-items: center;
					color: #fff;
					border: 1px solid #fff;
					padding: 5px 10px;
					margin: 5px;

					i {
						margin-right: 5px;
					}
				}
			}
		}
	}
}