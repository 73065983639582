@import "setup.css";

.navbarheader {
	position: fixed;
	z-index: 6;
	width: 100%;
	background: rgba(255, 255, 255, .8);
	margin-top: 15px;
	padding: 10px 0;
	top: 0;
	z-index: 3;


	.navbarheader @media (max-width: 992px) {
		margin-top: 0;
	}

	.container {
		max-width: 1300px;
		width: 1300px;

		@media (max-width: 992px) {
			width: 100%;
		}

		@media (max-width: 1300px) {
			width: 100%;
			max-width: 1300px;
		}
	}

	&.affix {
		background-color: var(--whitecolor);
		margin-top: 0;
		box-shadow: 0 0px 4px rgba(0, 0, 0, .1);


		.logo {
			max-width: 195px;
		}

		nav {
			height: 55px;
		}

		.nav-contact {
			@media (max-width: 992px) {
				top: 9px;
			}
		}
	}


	.logo {
		max-width: 195px;
		display: block;
	}



	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 60px;

		.icon-bar {
			background-color: var(--PrimaryColor);
		}

		.sr-only {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			margin: -1px;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			border: 0;
		}

		.icon-bar {
			display: block;
			width: 22px;
			height: 2px;
			border-radius: 1px;
			margin: 0px 0;
		}

		@media (max-width:992px) {}

		.navbar-area {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
			}

			@media (max-width:992px) {
				position: absolute;
				display: none;
				width: 100%;
				background-color: #fff;
				left: 0;
			}

			padding: 0;
			margin: 0;

			li {
				list-style: none;

				a {
					color: var(--PrimaryColor);

					&:hover {
						text-decoration: none;
						color: var(--secondarycolor);
					}
				}
			}
		}
	}


	.nav-contact {
		a {
			color: #a0927e;
			font-size: 15px;
			text-decoration: none !important;
			margin: 0 0 0 5px;
			letter-spacing: 0.5px;

			@media (max-width:992px) {
				margin-left: 5px;
			}
		}

		.fa {
			padding-top: 6px;
			padding-left: 7px;
			font-size: 13px;
			width: 27px;
			height: 27px;
			background: var(--PrimaryColor);
			color: #fff;
			border-radius: 100%;
			margin: 0 2px 0 0;
		}


		span {
			padding: 17px 2px;
			color: var(--blackcolor);
			font-size: 15px;

			@media (max-width:1240px) {
				font-size: 13px;
			}

			@media (max-width:1170px) {
				display: none;
			}
		}


		@media (max-width: 768px) {
			display: none;
		}
	}
}


.vo-nav-bar {

	a.nav-item {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: .05em;
		text-transform: uppercase;
		padding: 20px 10px;
		line-height: 83px;

		@media (max-width:1240px) {
			font-size: 13px;
			padding: 20px 4px;
		}

		@media (max-width:1170px) {
			padding: 20px 10px;
		}

		@media (max-width:992px) {
			line-height: initial;
			padding: 10px;
			font-size: 14px;
		}

		&:hover {
			color: var(--secondarycolor);
			text-decoration: none;
		}
	}


	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}


	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;

		&:hover {
			background: #cbcdce;
		}
	}



	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
			position: relative;
			color: #fff;
			color: var(--whitecolor);
			background-color: #dc143c;
			background-color: transparent;
			border-radius: 50%;
			width: 27px;
			height: 20px;
			text-align: center !important;
			margin-left: 15px;
			line-height: 27px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			display: block;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 100%;
			margin: 10px 0 0 0;
		}
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}