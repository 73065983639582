.booking-view {
	padding: 50px 0 20px 0;
	padding-top: 100px;

	.cart-details {
		h4 {
			font-size: 16pt;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.btn {
			display: none;
		}

		.price-details-btn {
			font-style: normal;
			color: var(--PrimaryColor);
			text-decoration: none;

			&:hover {
				color: var(--secondarycolor);
			}
		}
	}

	.pd-form {
		label {
			font-weight: 600 !important;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			.checkbox {
				display: flex;
				align-items: center;

				.doc-box {
					margin: 0 0 0 5px;

					a {
						font-style: normal;
						color: var(--PrimaryColor);
						text-decoration: none;

						&:hover {
							color: var(--secondarycolor);
						}
					}
				}
			}

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		border-radius: 0;
		outline: none;
		padding: 0 20px;
		height: 45px;
		line-height: 45px;
		font-size: 16px;
	}

	.btn-default {
		background: transparent;
		border: 1px solid var(--PrimaryColor);
		color: var(--PrimaryColor);

		&:hover {
			background: var(--secondarycolor);
			color: var(--whitecolor);
		}
	}

	.btn-go {
		background: var(--PrimaryColor);
		border: 1px solid var(--PrimaryColor);
		color: var(--whitecolor);

		&:hover {
			background: var(--secondarycolor);
			color: var(--whitecolor);
		}
	}

}